import React, { MouseEvent, useState, useRef, useEffect } from 'react';
import './App.css';
import { Canvas, MeshProps, useFrame } from '@react-three/fiber'
import { Content } from './Content'
import { Dodecahedron, Octahedron } from '@react-three/drei';

interface BoxProps {
  scroll: React.RefObject<number>
}

const Box = (props: BoxProps) => {
  const ref = useRef()

  useFrame(({ clock }) => {
    // @ts-ignore
    if (ref.current && ref.current.rotation && props.scroll && props.scroll.current) {
      // @ts-ignore
      ref.current.rotation.x = props.scroll.current * 10
      // @ts-ignore
      ref.current.rotation.y = props.scroll.current * 10
    }
  })

  return (
    <Dodecahedron ref={ref} args={[2, 0]}>
      <meshBasicMaterial color="white" />
    </Dodecahedron >
  )
}

function App() {
  const scrollRef = useRef(0)
  const onScroll = (event: MouseEvent<HTMLDivElement>) => {
    scrollRef.current = event.currentTarget.scrollTop / (event.currentTarget.scrollHeight - window.innerHeight)
  }
  const [isPrint, setIsPrint] = useState(false)

  useEffect(() => {
    const onPrintChange = (event: MediaQueryListEvent) => {
      setIsPrint(event.matches)
    }

    matchMedia('print').addEventListener('change', onPrintChange)

    return () => {
      matchMedia('print').removeEventListener('change', onPrintChange)
    }
  }, [])


  return (
    <div className="App" onScroll={onScroll}>
      <span id="top"></span>
      <Content isPrint={isPrint} />

      {/*
      Disabled for now.
      <Canvas style={{ position: 'fixed', top: '0px', left: '0px', zIndex: -1 }}>
        <ambientLight intensity={2.0} />
        <directionalLight color="#fff" position={[0, 0, 5]} />

        <Box scroll={scrollRef} />

      </Canvas>
      */}
      {!isPrint &&
        <div className="navigation">
          <h3>
            Haven't had enough? Go back:
          </h3>
          <ul>
            <a href="#top">Hi + Contact</a>
            <a href="#Work">Work</a>
            <a href="#OSS">OSS</a>
            <a href="#Tech">Tech</a>
            <a href="#Education">Education</a>
            <a href="#Personal">Personal</a>
          </ul>
        </div>
      }
    </div >
  );
}

export default App;

import { FunctionComponent, ReactNode, useEffect, useState } from "react";

type SectionProps = {
  header?: string,
  span?: number,
  title: string,
  year?: string,
  subTitle?: string,
  link?: string,
  children: ReactNode
}

export const Section: FunctionComponent<SectionProps> = ({ header, span, title, year, subTitle, children, link }) => {
  const [titleContent, setTitleContent] = useState(<></>)

  useEffect(() => {
    if (link) {
      setTitleContent(
        <a href={link}>{title}</a>
      )
    } else {
      setTitleContent(<span>{title}</span>)
    }
  }, [link, title])

  return (
    <>
      {(header || span) &&
        <div className="headerContainer" id={header} style={{ gridRow: `span ${span}` }}>
          <h1 className="header">{header}</h1>
        </div>
      }
      <div className="subTitles">
        <h2>{titleContent}</h2>
        {year &&
          <h3>{year}</h3>
        }
        <h3>{subTitle}</h3>
      </div>
      <div className="content">
        {children}
      </div>
    </>
  )
}


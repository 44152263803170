import "./Text.css";
import { Section } from "./Section";
import { useEffect, useState } from "react";

interface ContentProps {
  isPrint: boolean;
}

export const Content = (props: ContentProps) => {
  return (
    <div className="Text">
      <Section span={1} title="Hi!">
        <p>I'm Aske, and I'm a techie, programmer, and recovering CTO.</p>
        <p>
          I'm available for freelance work and consulting. I'm mainly focused on{" "}
          <mark>Elixir</mark>.
        </p>
        <p>
          I enjoy building things with impact: previously that meant something
          that as many people as possible interacted with. Lately, that has
          changed to mean business impact instead. Reach out if you want to
          discuss a project!
        </p>
        <p>
          Best,
          <br />
          Aske Dørge
        </p>
        {!props.isPrint ? (
          <div>
            <a
              href="mailto:aske.doerge@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email
            </a>
            |
            <a
              href="https://www.linkedin.com/in/askedoerge/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            |
            <a
              href="https://twitter.com/a_doerge"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </div>
        ) : (
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            Contact:
            <a
              href="mailto:aske.doerge@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              aske.doerge@gmail.com
            </a>
            <a
              href="https://www.linkedin.com/in/askedoerge/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/in/askedoerge
            </a>
          </div>
        )}
      </Section>

      <Section header="Work" span={6} title="Freelance" year="2021-now">
        I'm available for freelance work and consulting. I'm mainly focused on{" "}
        <mark>Elixir</mark>, but can also do some <mark>React </mark> if need
        be. In addition I can also do architecture, infrastructure (
        <mark>AWS </mark> + <mark>Terraform </mark> prefered), CI/CD setup,
        product discovery, and stakeholder management. I can churn out code for
        spec'ed out tickets, or run with a losely defined goal, and figure out
        what to build on the way. In addition I can step in as manager, help
        with board-meeting preparations, or due dilligence. I also have a small
        network of trusted peers to bring in for speciality tasks.
      </Section>
      <Section title="Projects" year="2021-now">
        <p>I'm currently noodling away on my own businesses:</p>
        <ol>
          <li>
            Together with my cofounder I built{" "}
            <a href="https://getloccal.com" target="_blank" rel="noreferrer">
              getloccal.com
            </a>
            We're offering subtitling, translation, voice-over (Text-to-Speech),
            and replacement of translated graphics within videos, all supported
            by our custom platform (<mark>Elixir/Phoenix LiveView + React</mark>
            ). Our customers are multi-national corporates and e-learning
            companies.
          </li>
          <li>
            I'm working solo on a custom jewelry bussiness{" "}
            <a href="https://arilie.com">arilie.com</a> that involves lots of
            3D, AI, and image processing which I've been doing since on-and-off
            since university. Mostly <mark>React</mark>, and <mark>Python</mark>
            . Stuck in marketing this thing now...
          </li>
          <li>
            I built a search engine for micro-influencers (
            <mark>Elixir/Phoenix LiveView</mark>), that crawls Instagram,
            TikTok, and Pinterest for relevant micro-influencers for SMBs to
            collaborate with. I utilized <mark>Elixir's</mark> excellent
            concurrency model for this. Ultimately the limiting factor is API
            usage throttling/circumvention. Abandoned and shut down.
          </li>
        </ol>
      </Section>

      <Section
        title="Stykka"
        year="2017-2021"
        link="https://www.crunchbase.com/organization/stykka"
      >
        <p>
          Stykka creates sustainable furniture for the future of construction.
          All parts in every piece of furniture have a digital twin, that
          enables easy repairs.
        </p>
        <p>
          At Stykka I worked as CTO and interim CFO (2 years), dealing with
          everything from strategy, finance, and KPIs, while also building out
          the tech.
        </p>
        <p>I led and build three products from scratch here:</p>
        <ol>
          <li>
            Core platform to track all furniture parts (digital twins). The
            platform acted as a mini ERP (
            <mark>Elixir/Phoenix + Vanilla JS</mark>). (All software converges
            to an ERP over time, amirite?).
          </li>
          <li>
            Desktop 3D CAD software (Rhino) plug-in (<mark>C#</mark>), that
            allows the designers to configure their designs with materials, and
            uploads data and 3D models (digital twin) to the core platform.
          </li>
          <li>
            Freeform configurator for laser cutting in the browser (similar
            functionality as to what Illustrator offers for Bezier paths
            manipulation) (<mark>Elixir/Phoenix + VueJS + AWS</mark>)
          </li>
        </ol>
        <p>
          Additionally, I took on the interim CFO role after one cofounder left.
          Here I learned everything from bookkeeping, contracts, auditing,
          balancing a very tight cash flow, and due diligence with both private
          investors, and public funds.
        </p>
        <em>Main takeaways:</em>
        <ul>
          <li>
            strategy, leadership (~15 people), communication and overall
            breaking out of the tech silo.
          </li>
          <li>
            <mark>Elixir</mark> and <mark>Javascript</mark>
          </li>
          <li>
            Changed my mindset from being perfectionist to ship-to-survive
          </li>
        </ul>
        This role fundamentally changed my perspective on tech within
        organizations.
      </Section>

      <Section
        title="Dubsmash"
        subTitle="Acquired by Reddit"
        year="2016"
        link="https://www.crunchbase.com/organization/dubsmash"
      >
        <p>
          Dubsmash was an early TikTok competitor in the social media video
          creation, sharing, and music space.
        </p>
        <p>
          I worked as a senior developer on the backend (
          <mark>Python/Django</mark>) of the system with +100 mio. users.
          Additionally, I took on the project of building cross-platform mobile
          “filters” (like Snapchat/Instagram's 3D “masks”) in{" "}
          <mark>C++ + Swift + Objective C</mark>.
        </p>
        <em>Main takeaways:</em>
        <ul>
          <li>
            how to build large-scale applications with 100s of thousands of
            online users.
          </li>
          <li>devops with oncall duties is hard</li>
          <li>working in a large agile team</li>
        </ul>
      </Section>

      <Section
        title="Wise.io"
        subTitle="Acquired by GE"
        year="2013-2015"
        link="https://www.crunchbase.com/organization/wise-io"
      >
        <p>
          Wise was an ML startup out of Berkely from a bunch of astrophysics
          professors, postdocs, and students.
        </p>
        <p>
          I was building out the internal machine learning platform in{" "}
          <mark>Python/Flask + AWS</mark>, at a time before every cloud provider
          offered click-to-deploy ML pipelines, and <mark>Docker</mark> was
          brand new.
        </p>
      </Section>
      <Section title="Prior Life" year="2009-2013">
        <p>
          I spent around a decade of my life on 3D/VFX, and freelanced for a
          couple of years. I was fortunate enough to teach a couple of courses
          at one of the best{" "}
          <a
            href="https://animationworkshop.via.dk/"
            target="_blank"
            rel="noreferrer"
          >
            schools
          </a>{" "}
          in Europe.
        </p>
        <p>
          Bonus: I have the World's{" "}
          <a
            href="https://www.imdb.com/name/nm3167621"
            target="_blank"
            rel="noreferrer"
          >
            most modest IMDB
          </a>{" "}
          with 2x titles!
        </p>
      </Section>

      <Section
        header="OSS"
        span={2}
        title="glTFBinExporter"
        subTitle="3D asset exporter"
      >
        <p>
          glTF is an open standard for transmission of 3D assets. It's the next
          big thing, as "metaverses", gaming, and XR, become prevalent across
          other sectors such as e-commerce, and remote work in the next 5 years.
        </p>
        <p>
          I wrote an{" "}
          <a
            href="https://github.com/Stykka/glTF-Bin"
            target="_blank"
            rel="noreferrer"
          >
            open source exporter
          </a>{" "}
          in <mark>C#</mark> for the popular 3D application Rhinocerous to glTF.
          It's now the de-facto standard of exporting glTF from Rhino, and has
          been adopted by the company that creates Rhino: McNeel.
        </p>
      </Section>

      <Section title="Zappa" subTitle="Serverless Python">
        <p>
          <a
            href="https://github.com/Miserlou/Zappa"
            target="_blank"
            rel="noreferrer"
          >
            Zappa
          </a>{" "}
          is a very popular serverless Python framework.{" "}
          <a
            href="https://github.com/Doerge/Zappa"
            target="_blank"
            rel="noreferrer"
          >
            I wrote
          </a>{" "}
          the original WSGI server for <mark>Flask</mark>, but it was generic
          enough to be used by all WSGI frameworks (all mainstream Python
          servers conform to WSGI). It was lifted out and into the main Zappa
          package.
        </p>
      </Section>

      <Section header="Tech" span={4} title="Favorites">
        <p>
          If I have no constraints, I'm mostly into <mark>Elixir/Phoenix</mark>{" "}
          + <mark>React</mark> for web. I like functional, strict languages like
          Haskell, or ML, but I'm productive in Elixir these days.
        </p>
        <p>
          Most algorithm papers describe imperative solutions which can be hard
          to rewrite to functional, and even harder to analyze the complexity
          of.{" "}
          <a
            href="https://literal.club/aske/book/purely-functional-data-structures-re40d"
            target="_blank"
            rel="noreferrer"
          >
            This book
          </a>{" "}
          helps, although I find it non-trivial to apply to <mark>Elixir</mark>.
        </p>
        <p>
          Neovim user since Onivim2 stopped development. Still working on my
          config though...
        </p>
        <p>
          Down with GUIs! All hail Terminals! My terminal{" "}
          <a
            href="https://gist.github.com/Doerge/cd1fb1cc5e7d5ea3e198f953457e6310"
            target="_blank"
            rel="noreferrer"
          >
            setup
          </a>{" "}
          is iTerm2 + zsh + fzf.
        </p>
      </Section>

      <Section title="Touched">
        <p>
          In no particular order, I have previously worked with: Python (Flask,
          Django, numpy, pandas, etc), Docker, Postgres, Redis, BLAS, cuda,
          cuDNN, cuBLAS, Terraform, ReactJS, VueJS, git, AWS.
        </p>
      </Section>

      <Section title="Langs">
        <p>
          I can learn any language, fairly fast, because I've sniffed around
          almost every paradigm: imperative, functional, declarative.
          Profesionally I've programmed in: Elixir, Javascript, C#, Python,
          Swift, C++. At University for shorter or longer: Haskell, Erlang,
          Prolog, Matlab, R, Moscow ML, C, assembly (RISK), Java.
        </p>
      </Section>

      <Section title="Areas">
        <p>
          These days I'm mainly doing Web stuff, but I do have a little
          experience with Desktop, iOS, GPUs, and Python for supercomputing. I
          suck at kernel, and embedded programming, and don't think I will ever
          really learn or enjoy it.
        </p>
      </Section>

      <Section
        header="Education"
        span={1}
        title="BSc & MSc"
        year="2009-2015"
        subTitle="Computer Science"
      >
        <p>
          I went to DIKU (Datalogisk Institut Københavns Universitet / Computer
          Science, University of Copenhagen) where I focused on algorithms and
          computer graphics in my bachelor, and image processing and machine
          learning during my master.
        </p>
        <p>
          Sadly I have forgotten a lot of the math I toiled away over, but the
          good thing about having learned it once is that it's a lot easier to
          relearn the 2nd time. At Dubsmash I worked on a super hard problem,
          where I eventually opted for{" "}
          <a
            href="https://en.wikipedia.org/wiki/Kalman_filter"
            target="_blank"
            rel="noreferrer"
          >
            Kalman Filtering
          </a>
          , which I would never have known to lookup without having learned
          about it at Uni. At Stykka I implemented a variation of the{" "}
          <a
            href="https://en.wikipedia.org/wiki/Cutting_stock_problem"
            target="_blank"
            rel="noreferrer"
          >
            cutting stock problem
          </a>
          , which I could recognize from the 1st year intro to Algorithms
          course. Even though I can't recall how to do Taylor Expansions or
          L'Hopitals rule, tasks like the above become easier when you know what
          to look for.
        </p>
      </Section>

      <Section header="Personal" span={2} title="Sport">
        <p>
          I like rock climbing and bouldering and have climbed multi-pitch
          routes in the Alps (hundreds of meters). The challenges are many:
          mind, strength, body mechanics, and logistics, all need to come
          together for a successful and safe ascent. Hanging 100m above the
          ground on a vertical mountainside, just you and your buddy is
          beautiful. I plan to do much more of that in the future.
        </p>
      </Section>
      <Section title="Interests">
        <p>
          I'm reading books in a variety of genres from fiction to
          self-betterment, from Nobel laureates to airport authors. My current
          favorite authors are William Gibson, Orhan Pamuk, Ursula K. LeGuin,
          and Jack Keourac. Like so many others I have a goal of reading more
          than I do. Here's my{" "}
          <a
            href="https://app.literal.club/aske"
            target="_blank"
            rel="noreferrer"
          >
            Literal profile
          </a>{" "}
          to see more.
        </p>
        <p>
          Over the past couple of years, I took an interest in finance and money
          markets. I listen to podcasts and newsletters, (my favorites are{" "}
          <a
            href="https://ritholtz.com/category/podcast/"
            target="_blank"
            rel="noreferrer"
          >
            this
          </a>
          ,{" "}
          <a
            href="https://www.bloomberg.com/account/newsletters/money-stuff"
            target="_blank"
            rel="noreferrer"
          >
            this
          </a>
          , and{" "}
          <a href="https://bam.kalzumeus.com/" target="_blank" rel="noreferrer">
            this
          </a>
          ) and through my interim CFO stint, I've learned a lot about it from
          the perspective of a small company.
        </p>
      </Section>
    </div>
  );
};
